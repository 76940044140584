import styled from "styled-components";

export const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const Side = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 2.5vw;
    width: 40vw;
    height: fit-content;

    &.left-side {
        align-items: flex-start;
    }

    @media screen and (max-width: 1000px) {
        width: 80vw;

        &.left-side {
            align-items: center;
        }
    }
`;

export const ContactForm = styled.form`
    margin: 25px auto;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Box = styled.div`
    width: 100%;
    margin: 10px auto;
    padding-left: 10%;
`;

export const BoxTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
`;

export const BoxInput = styled.input`
    font-size: 1.5rem;
    padding: 10px;
    width: 90%;
`;

export const BoxMessage = styled.textarea`
    font-size: 1.5rem;
    padding: 10px;
    width: 90%;
    height: 150px;
    margin-bottom: 50px;
`;


export const Checkbox = styled.input`
    transform: scale(1.75);
`;

export const SendButton = styled.button`
    margin: 50px auto;
    background: #0072ce;
    color: #fff;
    height: 50px;
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #0072ce; 
    border-radius: 10px;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.2);

    &:hover {
        background: #fff;
        color: #0072ce;
    }
`;

export const SideGroup = styled.div`
    margin: 15px 0;
`;

export const SideInfo = styled.h2`
    text-align: left;
    justify-content: left;
    font-size: 1.25rem;

    @media screen and (max-width: 1000px) {
        text-align: center;
    }
`;

export const ApprovalSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const ApprovalText = styled.p`
    font-size: 1.5rem;
    margin: 10px 20px;
`;

export const AlertSuccess = styled.div`
    color: #28a745;
    background: #d4edda;
    width: 100%;
    height: 75px;
    min-height: fit-content;
    border-radius: 10px;
    font-size: 1.5rem;
    text-align: center;
`;

export const AlertFail = styled.div`
    color: #dc3545;
    background: #f8d7da;
    width: 100%;
    height: 75px;
    min-height: fit-content;
    border-radius: 10px;
    font-size: 1.5rem;
    text-align: center;
`;

export const AlertWarning = styled.div`
    color: #aa5502;
    background: #fff3cd;
    width: 100%;
    height: 75px;
    min-height: fit-content;
    border-radius: 10px;
    font-size: 1.5rem;
    text-align: center;
`;