import styled from "styled-components";

export const Container = styled.div`
    padding: 100px 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
`;

export const TitleLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    margin: 0 3vw;

    @media screen and (max-width: 1000px) {
        justify-content: center;
    }

    &.centerTitle {
        justify-content: center;
    }
`;

export const Title = styled.h1`
    font-family: 'Playfair Display';
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: 3.25rem;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 50px;

    @media screen and (max-width: 1000px) {
        font-size: 2.75rem;
    }
`;