import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { SkewedContainer, VerticalContainer, Logo, Title, Button, SubtitleList, Element, Description} from '../components/Sections/Home/Elements';
import './home.css';

function HomeSection() {
    return (
        <div id="home">
            <SkewedContainer>
                <VerticalContainer className="mainVerticalContainer">
                    <Logo src="../images/LogoProgresiv.png"/>
                    <VerticalContainer>
                        <Title>PROGRESIV MEDICAL</Title>
                        <SubtitleList>
                            <Element>
                                <FontAwesomeIcon icon={faCheckSquare} />
                                <Description>Workshop Noduri și Suturi in Chirurgie</Description>
                            </Element>
                            <Element>
                                <FontAwesomeIcon icon={faCheckSquare} />
                                <Description>Workshop Manevre Medicale Invazive Esențiale</Description>
                            </Element>
                        </SubtitleList>
                        <Button href="https://docs.google.com/forms/d/e/1FAIpQLSfqJCTjxZdoLUuHFSfDcdyvRqOS9trVVZKLE_M2uap8yCFDKg/formResponse" target="_blank">Înscrie-te acum!</Button>
                    </VerticalContainer>
                </VerticalContainer>
            </SkewedContainer>
        </div>
    )
}

export default HomeSection;