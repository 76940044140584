import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import {BrowserRouter as Router} from 'react-router-dom';
import HomeSection from './Sections/Home';
import AboutSection from './Sections/About';
import GallerySection from './Sections/Gallery';
import ContactSection from './Sections/Contact';
import FooterBar from './components/Footer/index';

function App() {
  return (
    <Router>
      <NavBar/>
      <HomeSection/>
      <AboutSection/>
      <GallerySection/>
      <ContactSection/>
      <FooterBar/>
    </Router>
  );
}

export default App;
