export const GalleryImages = [
    {
        title: '',
        src: '../images/Image1.jpeg'
    },
    {
        title: '',
        src: '../images/Image2.jpeg'
    },
    {
        title: '',
        src: '../images/Image3.jpeg'
    },
    {
        title: '',
        src: '../images/Image4.jpeg'
    },
    {
        title: '',
        src: '../images/Image5.jpeg'
    },
    {
        title: '',
        src: '../images/Image6.jpeg'
    },
    {
        title: '',
        src: '../images/Image7.jpeg'
    },
    {
        title: '',
        src: '../images/Image8.jpeg'
    },
    {
        title: '',
        src: '../images/Image9.jpeg'
    },
    {
        title: '',
        src: '../images/Image10.jpeg'
    },
    {
        title: '',
        src: '../images/Image11.jpeg'
    },
    {
        title: '',
        src: '../images/Image12.jpeg'
    },
    {
        title: '',
        src: '../images/Image13.jpeg'
    },
    {
        title: '',
        src: '../images/Image14.jpeg'
    },
    {
        title: '',
        src: '../images/Image15.jpeg'
    },
    {
        title: '',
        src: '../images/Image16.jpeg'
    },
    {
        title: '',
        src: '../images/Image17.jpeg'
    },
    {
        title: '',
        src: '../images/Image18.jpeg'
    },
    {
        title: '',
        src: '../images/Image19.jpeg'
    },
    {
        title: '',
        src: '../images/Image20.jpeg'
    },
    {
        title: '',
        src: '../images/Image21.jpeg'
    },
    {
        title: '',
        src: '../images/Image22.jpeg'
    },
    {
        title: '',
        src: '../images/Image23.jpeg'
    },
    {
        title: '',
        src: '../images/Image24.jpeg'
    },
    {
        title: '',
        src: '../images/Image25.jpeg'
    },
    {
        title: '',
        src: '../images/Image26.jpeg'
    },
    {
        title: '',
        src: '../images/Image27.jpeg'
    },
    {
        title: '',
        src: '../images/Image28.jpeg'
    },
    {
        title: '',
        src: '../images/Image29.jpeg'
    },
    {
        title: '',
        src: '../images/Image30.jpeg'
    },
    {
        title: '',
        src: '../images/Image31.jpeg'
    },
    {
        title: '',
        src: '../images/Image32.jpeg'
    },
    {
        title: '',
        src: '../images/Image33.jpeg'
    },
    {
        title: '',
        src: '../images/Image34.jpeg'
    },
    {
        title: '',
        src: '../images/Image35.jpeg'
    },
    {
        title: '',
        src: '../images/Image36.jpeg'
    },
    {
        title: '',
        src: '../images/Image37.jpeg'
    },
    {
        title: '',
        src: '../images/Image38.jpeg'
    },
    {
        title: '',
        src: '../images/Image39.jpeg'
    },
];
