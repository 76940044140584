import styled from "styled-components";

export const SkewedContainer = styled.div`
    @media screen and (min-width: 1000px) {
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        background: #fff;
        padding-left: 175px;
        height: 100%;
        width: 90%;
        min-height: calc(100vh - 80px);
        overflow: hidden;
        position: relative;
        left: -25%;
    }
`;

export const Logo = styled.img`
    height: 300px;
    padding: 0 75px;

    @media screen and (max-width: 1000px) {
        height: 25vw;
    }
`;

export const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h1`
    // color: #a16d33;
    margin: 25px auto;
    font-size: 3.6rem;
    font-weight: 500;
    text-align: center; 

    @media screen and (max-width: 1000px) {
        font-size: 2.5rem;
    }
`;

export const Button = styled.a`
    margin-top: 25px;
    padding: 10px;
    background: #0072ce;
    color: #fff;
    text-decoration: none;
    text-align: center;
    height: 50px;
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #0072ce; 
    border-radius: 10px;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.2);

    &:hover {
        background: #fff;
        color: #0072ce;
    }
`;

export const SubtitleList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin: 10px auto;
`;

export const Element = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
`;

export const Description = styled.h3`
    margin: 5px;
`;

