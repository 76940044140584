import React, {useState} from 'react';
import {Nav, NavLink, SidebarNavLink, Bars, NavMenu, NavBtn, SocialMediaLink, SidebarNav, SidebarSocial, Sidebar, CloseSign} from './Elements'; 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SectionLinks, SocialMediaLinks} from '../Links';

const NavBar = () => {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    function smoothScroll(event: { preventDefault: () => void; }, elemId: string) {
        let elem = document.getElementById(elemId);
        event.preventDefault();
        elem && elem.scrollIntoView({ behavior: "smooth", block: "start"});
    }

    return (
        <div className="navigation">
            <Nav>
                <NavLink href ="#home" onClick={e => smoothScroll(e, "home")}>
                    <img src={require("../../images/LogoProgresivBlue.png")} alt="logo" />
                </NavLink>
                {!sidebar ? <Bars onClick={showSidebar}/> : <CloseSign onClick={showSidebar}/>}
                <NavMenu>
                    {SectionLinks.map((item, index) => {
                        if (item.title === 'Înscrieri') {
                            return (
                                <NavLink href={item.path} key={index} target={item.target}>
                                    {item.title}
                                </NavLink>
                            );
                        }
                        return (
                            <NavLink href={item.path} key={index} target={item.target} onClick={e => smoothScroll(e, item.path.substr(1))}>
                                {item.title}
                            </NavLink>
                        );
                    })}
                </NavMenu>
                <NavBtn>
                    {SocialMediaLinks.map((item, index) => {
                            return (
                                <SocialMediaLink href={item.href} key={index} target="_blank">
                                    <FontAwesomeIcon icon={item.icon} className="icon"/>
                                </SocialMediaLink>
                            );
                    })}
                </NavBtn>
            </Nav>

            <Sidebar>
                <SidebarNav className={sidebar ? 'sidebar-active' : 'sidebar'}>
                    <SidebarNavLink href ="#home" onClick={e => {
                                    showSidebar();
                                    smoothScroll(e, "home");
                        }}>
                        <img src={require("../../images/LogoProgresivBlue.png")} alt="logo" />
                    </SidebarNavLink>

                    {SectionLinks.map((item, index) => {
                        if (item.title === 'Înscrieri') {
                            return (
                                <NavLink href={item.path} key={index} className="menu-link" target={item.target}>
                                    {item.title}
                                </NavLink>
                            );
                        }
                        return (
                            <NavLink href={item.path} key={index} className="menu-link" target={item.target} onClick={e => {
                                    showSidebar();
                                    smoothScroll(e, item.path.substr(1));
                                }}>
                                {item.title}
                            </NavLink>
                        );
                    })}

                    <SidebarSocial>
                        {SocialMediaLinks.map((item, index) => {
                            return (
                                <SocialMediaLink href={item.href} key={index} target="_blank">
                                    <FontAwesomeIcon icon={item.icon} className="icon"/>
                                </SocialMediaLink>
                            );
                        })}
                    </SidebarSocial>

                    {/* <CopyrightText>
                        <FontAwesomeIcon icon={faCopyright} className="copyright-icon"/>
                        2022 Asociatia Progresiv Medical
                    </CopyrightText> */}
                    
                </SidebarNav>
            </Sidebar>
        </div>
    )
}


export default NavBar;