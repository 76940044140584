import styled from "styled-components";
import { FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';

export const GalleryView = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, auto);
    }
`;

export const ImageButton = styled.a`
    position: relative;
    height: fit-content;
    width: fit-content;
`;

export const GalleryImage = styled.img`
    width: calc((100vw - 300px) / 3);
    height: calc((100vw - 300px) / 3);
    object-fit: cover;
    object-position: center;
    border: 10px solid #eaeaea;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 1000px) {
        width: 80vw;
        height: 80vh;
        max-height: 500px;
        // height: 500px;
    }
`;


export const FullScreenContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 80px;
    z-index: 100;
    display: none;

    &.active {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (min-width: 500px) {
        padding: 50px;
    }

    @media screen and (max-width: 1000px) {
        height: calc(100vh - 60px);
        top: 60px;
    }
`;

export const SelectedImage = styled.img`
    @media screen and (orientation: landscape) {
        height: 80vh;
    }

    @media screen and (orientation: portrait) {
        width: 60vw;
    }
`;

export const CloseSign = styled(FaTimes)`
    color: #fff;
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 2.5rem;
    cursor: pointer;
`;

export const LeftArrow = styled(FaArrowLeft)`
    color: #fff;
    display: block;
    font-size: 2.5rem;
`;

export const RightArrow = styled(FaArrowRight)`
    color: #fff;
    display: block;
    font-size: 2.5rem;
`;