import React from 'react';
import {InsideContainer, HorizontalContainer, Side, AboutParagraph, AboutSubtitle, Bullets, Bullet, SideGroup, SideInfo, ImageMap, InfoButton, LectorView, TrainerView, Member, MemberImage, MemberName} from '../components/Sections/About/Elements';
import {Container, TitleLine, Title} from '../components/utils/Elements';
import AboutImage from '../components/Sections/About/AboutImage';
import './about.css';

function AboutSection() {

    function smoothScroll(event: { preventDefault: () => void; }, elemId: string) {
        let elem = document.getElementById(elemId);
        event.preventDefault();
        elem && elem.scrollIntoView({ behavior: "smooth", block: "start"});
    }

    return (
        <div id="about">
            <Container>
                <TitleLine>
                    <Title id="title">
                        Despre Noi - Asociația Progresiv
                    </Title>
                </TitleLine>
                <HorizontalContainer>
                    <Side>
                        <AboutParagraph>
                            De la pasiunea pentru medicină la practicarea ei, Asociația Progresiv se asigură că studenții de astăzi vor fi medicii bine pregătiți din viitorul apropiat. 
                        </AboutParagraph>
                        <AboutParagraph>
                            Susținem și încurajăm studenții tuturor Universităților de Medicină și Farmacie din țară în primii lor pași spre o carieră de succes. Și cum putem face asta mai bine, decât prin workshop-uri practice alături de medici cu expertiză care se dedică complet progresului prin educație?!
                        </AboutParagraph>
                        <AboutParagraph>
                            Într-o atmosferă plăcută și caldă, echipa noastră este pregătită să vă fie alături pe parcursul procesului de învățare și formare, unde veți exersa și aprofunda informațiile prezentate.
                        </AboutParagraph>
                    </Side>
                    <Side className="imageSide">
                        <AboutImage src="../images/RuxiProgresiv.png"/>
                    </Side>
                </HorizontalContainer>
                <TitleLine className="centerTitle">
                    <Title>
                        WORKSHOP-URI
                    </Title>
                </TitleLine>
                <HorizontalContainer>
                    <Side className="imageSide">
                        <AboutImage src="../images/Image13.jpeg"/>
                    </Side>
                    <Side>
                        <AboutSubtitle>
                            WORKSHOP NODURI ȘI SUTURI:
                        </AboutSubtitle>
                        <AboutParagraph>
                            Vor fi prezentate noțiuni de bază despre nodurile și suturile utilizate în chirurgie, cât si instrumentarul necesar.
                        </AboutParagraph>
                        <AboutParagraph>
                            1. Îmbracarea Halatului Steril
                        </AboutParagraph>
                        <AboutParagraph>
                            2. Îmbracarea Mănușilor Sterile
                        </AboutParagraph>
                        <AboutParagraph>
                            3. Prezentarea Instrumentarului Chirurgical
                        </AboutParagraph>
                        <AboutParagraph>
                            4. Nodul Chirurgical Simplu
                        </AboutParagraph>
                        <AboutParagraph>
                            5. Nodul Chirurgical Pouchet
                        </AboutParagraph>
                        <AboutParagraph>
                            6. Nodul Chirurgical Pe Pensă
                        </AboutParagraph>
                        <AboutParagraph>
                            7. Suturi Chirurgicale:
                        </AboutParagraph>
                        <Bullets>
                            <Bullet>▪ Fire Separate</Bullet>
                            <Bullet>▪ Fire Separate Blair-Donati</Bullet>
                            <Bullet>▪ Fire Separate In "U" Inversat</Bullet>
                            <Bullet>▪ Sutura Continuă De Tip Surjet Simplu</Bullet>
                            <Bullet>▪ Sutura Continuă De Tip Surjet Împiedicat</Bullet>
                            <Bullet>▪ Sutura Continua De Tip Intradermic</Bullet>
                        </Bullets>
                        <AboutParagraph>
                            8. Principii Tratament Și Îngrijire Plagă Operatorie
                        </AboutParagraph>
                    </Side>
                </HorizontalContainer>
                <HorizontalContainer>
                    <Side>
                        <AboutSubtitle>
                            WORKSHOP MANEVRE MEDICALE INVAZIVE ESENȚIALE:
                        </AboutSubtitle>
                        <AboutParagraph>
                            Un workshop util tuturor, indiferent dacă se urmărește o carieră în chirurgie sau nu. La acest workshop participantul va avea parte de o experiență cât mai aproape de realitate în următoarele manevre:
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	recoltare analize de sânge
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	injecție intravenoasă
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃   injecție intramusculară
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	injecție subcutană
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	montare branulă
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	preparare perfuzie
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	montare sondă urinară
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃	montare sondă nazogastrică
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃ tușeu rectal
                        </AboutParagraph>
                        <AboutParagraph>
                            ⁃ tușeu vaginal
                        </AboutParagraph>
                    </Side>
                    <Side className="imageSide">
                        <AboutImage src="../images/Image8.jpeg"/>
                    </Side>
                </HorizontalContainer>
                <InsideContainer>
                    <TitleLine className="centerTitle">
                        <Title>
                            Echipa Noastră
                        </Title>
                    </TitleLine>
                    <AboutParagraph>
                        Echipa noastră este formată din medici primari, specialiști si rezidenți sub coordonarea Dr. George Jinescu.
                    </AboutParagraph>
                    <AboutSubtitle>
                        Lectori
                    </AboutSubtitle>
                    <InsideContainer>
                        <LectorView>
                            <Member>
                                <MemberImage src="../images/DrGeorgeJinescu.jpeg"/>
                                <MemberName>Șef de Lucrari</MemberName>
                                <MemberName>Dr. GEORGE JINESCU,</MemberName>
                                <MemberName>Medic Primar Chirurgie Generală</MemberName>
                            </Member>
                            <Member>
                                <MemberImage src="../images/DrCorneliaChidiosan.jpeg"/>
                                <MemberName>Dr. CORNELIA CHIDIOSAN,</MemberName>
                                <MemberName>Medic Specialist Chirurgie Generală</MemberName>
                            </Member>
                            <Member>
                                <MemberImage src="../images/dr-andra-evtodiev.jpg"/>
                                <MemberName>Dr. ANDRA EVTODIEV, </MemberName>
                                <MemberName>Medic Specialist Chirurgie Generală</MemberName>
                            </Member>
                        </LectorView>
                    </InsideContainer>
                </InsideContainer>
                <InsideContainer>
                    <AboutSubtitle>
                        Traineri
                    </AboutSubtitle>
                    <InsideContainer>
                        <TrainerView>
                            <Member>
                                <MemberImage src="../images/blank-profile-picture.png"/>
                                <MemberName>Dr. IULIA MARIN,</MemberName>
                                <MemberName>Medic Rezident Chirurgie Generală</MemberName>
                            </Member>
                            <Member>
                                <MemberImage src="../images/SoareAlexandru.jpeg"/>
                                <MemberName>Dr. ALEXANDRU SOARE,</MemberName>
                                <MemberName>Medic Rezident Chirurgie Generală</MemberName>
                            </Member>
                            <Member>
                                <MemberImage src="../images/blank-profile-picture.png"/>
                                <MemberName>IOANA JINESCU,</MemberName>
                                <MemberName>Student UMFCD</MemberName>
                            </Member>
                        </TrainerView>
                    </InsideContainer>
                </InsideContainer>

                <TitleLine className="centerTitle">
                    <Title>
                        Începuturile Progresiv și Valorile După Care Ne Ghidăm
                    </Title>
                </TitleLine>

                <AboutParagraph>
                    Considerăm că educația este stâlpul oricărei societăți. Tocmai de aceea, atunci când ne-am întâlnit față-n față cu lipsurile existente în formarea viitorilor medici, am decis să luăm problema în propriile mâini și să găsim soluții adecvate.
                </AboutParagraph>
                <AboutParagraph>
                    Așadar, dorința studenților de a se implica în practica medicală curentă (cu ajutorul căreia nu numai că dobândesc experiență, ci și aprofundează informațiile teoretice deprinse la facultate), ne-a inspirat să creăm cele două workshop-uri expuse.  
                </AboutParagraph>
                <AboutParagraph>
                    La baza valorilor noastre stau două concepte importante: educația și progresul. Astfel, remodelarea sistemului educațional medical prin deschiderea către tinerii studenți este soluția în care noi credem și cu ajutorul căreia vom evolua. Formând generații care se ghidează după valori și principii puternice, vom reuși să îmbunătățim atât viața noastră de zi cu zi, cât și lumea în care toți trăim. 
                </AboutParagraph>
                <AboutParagraph>
                    Mai mult decât atât, suntem siguri că deși sistemul de sănătate este unul complex, prin cooperarea celor implicați în el și nu numai, putem pune bazele unei restaurări structurale eficiente.
                </AboutParagraph>

                <TitleLine className="centerTitle">
                    <Title>
                        Informații Utile
                    </Title>
                </TitleLine>

                <HorizontalContainer>
                    <Side className="left-side">
                        <SideGroup>
                            <SideInfo>UNDE?</SideInfo>
                            <SideInfo className="lightFont">Str. Mihai Eminescu nr.138, sector 2, Bucuresti</SideInfo>
                        </SideGroup>
                        <SideGroup>
                            <SideInfo>CAND?</SideInfo>
                            <SideInfo className="lightFont">Datele desfășurării următoarelor workshopuri vor fi postate pe pagina de Instagram <a href="https://www.instagram.com/progresivmedical/?hl=en" target="_blank" rel="noreferrer">@progresivmedical</a></SideInfo>
                        </SideGroup>
                        <SideGroup>
                            <SideInfo>TAXA?</SideInfo>
                            <SideInfo className="lightFont">150 lei/workshop</SideInfo>
                        </SideGroup>
                    </Side>
                    <Side className="imageSide">
                        <ImageMap src="../images/AddressMap.png"/>
                    </Side>
                </HorizontalContainer>

                <SideInfo>
                    Pentru întrebări si nelămuriri nu ezitați să ne contactați:
                </SideInfo>
                <HorizontalContainer>
                    <InfoButton href="https://docs.google.com/forms/d/e/1FAIpQLSfqJCTjxZdoLUuHFSfDcdyvRqOS9trVVZKLE_M2uap8yCFDKg/formResponse" target="_blank">Înscrie-te aici</InfoButton>
                    <InfoButton href="#contact" onClick={e => smoothScroll(e, "contact")}>Contactează-ne</InfoButton>
                </HorizontalContainer>

            </Container>
        </div>
    )
}

export default AboutSection;