import React from 'react';
import {Footer, CoordinatorArea, InsideCoordinator, Coordinator, SponsorArea, SponsorsList, SponsorImage, SectionTitle, SponsorTitle, Copyright, CopyrightText, TextLine, Cookies, CookiesTitle} from './Elements';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
// import { Sponsors } from '../Links';

const FooterBar = () => {
    return (
        <div className="footer">
            <Footer>
                <CoordinatorArea>
                    <SectionTitle>Coordonatori:</SectionTitle>
                    <InsideCoordinator>
                        <Coordinator>
                            Coordonator Departament Științific: <br></br> Șef de Lucrări Dr. George Jinescu 
                        </Coordinator>
                        <Coordinator>
                            Coordonator Departament Tehnic: <br></br>Ec.Cristina Neagu 
                        </Coordinator>
                        <Coordinator>
                            Coordonator Departament Marketing: <br></br> Prof. Univ. Dr. Cosmin Jinescu 
                        </Coordinator>
                        <Coordinator>
                            Coordonator Departament Comunicare: <br></br> Ioana-Valeria Jinescu 
                        </Coordinator>
                    </InsideCoordinator>
                </CoordinatorArea>
                <SponsorArea>
                    <SectionTitle>Sponsored by:</SectionTitle>
                    <SponsorsList className="longList">
                        <SponsorImage src="../../images/Sponsor1.png"/>
                        <SponsorImage src="../../images/Sponsor2.png"/>
                        <SponsorImage src="../../images/Sponsor3.png"/>
                        <SponsorImage src="../../images/Sponsor4.png"/>
                        <SponsorImage src="../../images/Sponsor5.png"/>
                        <SponsorImage src="../../images/Sponsor6.png"/>
                        <SponsorImage src="../../images/Sponsor7.png"/>
                    </SponsorsList>
                    <SectionTitle>Powered by:</SectionTitle>
                    <SponsorsList>
                        <SponsorImage src="../../images/PoweredBy.png"/>
                    </SponsorsList>
                    <SponsorTitle>COSMOPOLITAN/DECAN BUCURESTI</SponsorTitle>
                </SponsorArea>
                <Cookies>
                    <CookiesTitle>
                        Cookie-uri si Folosirea Datelor Personale
                    </CookiesTitle>
                    <TextLine>
                        Aceasta pagina web nu foloseste cookie-uri proprii.
                    </TextLine>
                    <TextLine>
                        Prin furnizarea datelor personale din sectiunea de Contact, va dati acordul sa va putem contacta inapoi pentru a solutiona cererea.
                    </TextLine>
                    <TextLine>
                        Datele de contact furnizate nu vor fi memorate si vor fi folosite doar in scopul solutionarii cererii dumneavoastra.
                    </TextLine>
                </Cookies>   
                <Copyright>
                    <CopyrightText>
                        <FontAwesomeIcon icon={faCopyright} className="copyright-icon"/>
                        2022 Asociatia Progresiv Medical
                    </CopyrightText>
                    <CopyrightText>
                        Toate drepturile rezervate
                    </CopyrightText>
                </Copyright>             
            </Footer>
        </div>
    )
}


export default FooterBar;