import React, {useState} from 'react';
import {Container, Title, TitleLine} from '../components/utils/Elements';
import {HorizontalContainer, Side, ContactForm, Box, BoxTitle, BoxInput, BoxMessage, SideInfo, SideGroup, Checkbox, SendButton, ApprovalSection, ApprovalText, AlertSuccess, AlertFail, AlertWarning} from '../components/Sections/Contact/Elements';
import './contact.css';
import emailjs, {init} from '@emailjs/browser';

function ContactSection() {

    const [successState, setSuccess] = useState(false);
    const [failState, setFail] = useState(false);
    const [warningState, setWarning] = useState(false);
    const [approvedCondition, setApprovedCondition] = useState(false);

    const approved = () => setApprovedCondition(!approvedCondition);

    async function sendEmail(e: { preventDefault: () => void; }) {
        if (!approvedCondition) {
            var form = document.getElementById("emailForm") as HTMLFormElement;
            form.reset();
            console.log("You need to check the box to submit the message");
            setWarning(true);
            setTimeout(function () {
                setWarning(false);
                console.log(warningState);
            }, 5000);
        } else {
            console.log("Attempting to send email...");

            const userId = process.env.REACT_APP_USER_ID as string;
            const serviceId = process.env.REACT_APP_SERVICE_ID as string;
            const templateId = process.env.REACT_APP_TEMPLATE_ID as string;

            e.preventDefault();
            init(userId);
            console.log(userId);

            emailjs.sendForm(serviceId, templateId, '#emailForm', process.env.REACT_APP_USER_ID)
                .then((result: { text: any; }) => {
                    var form = document.getElementById("emailForm") as HTMLFormElement;
                    form.reset();
                    console.log("Message Sent, We will get back to you shortly", result.text);
                    setSuccess(true);
                    setTimeout(function () {
                        setSuccess(false);
                    }, 5000);
                },
                    (error: { text: any; }) => {
                        setFail(true);
                        setTimeout(function () {
                            setFail(false);
                        }, 5000);
                        console.log("An error occurred, Please try again", error.text);
                }
            );
        }
    }

    return (
        <div id="contact">
            <Container>
                <TitleLine>
                    <Title id="title">
                        Contact
                    </Title>
                </TitleLine>
                <ContactForm id="emailForm" method="post">
                    <HorizontalContainer>
                        <Side>
                            <Box>
                                <BoxTitle>Nume si Prenume</BoxTitle>
                                <BoxInput placeholder="Nume si Prenume" id="name" name="name" required/>
                            </Box>
                            <Box>
                                <BoxTitle>Email</BoxTitle>
                                <BoxInput placeholder="Email" id="email" name="email" required/>
                            </Box>
                            <Box>
                                <BoxTitle>Mesajul Dvs.</BoxTitle>
                                <BoxMessage placeholder="Mesaj ..." id="message" name="message" required/>
                            </Box>
                        </Side>
                        <Side className="left-side">
                            <SideGroup>
                                <SideInfo>Adresa:</SideInfo>
                                <SideInfo>Str. Mihai Eminescu nr.138, sector 2, Bucuresti</SideInfo>
                            </SideGroup>
                            <SideGroup>
                                <SideInfo>Email:</SideInfo>
                                <SideInfo>progresivmedical@gmail.com</SideInfo>
                            </SideGroup>
                            <SideGroup>
                                <SideInfo>Telefon:</SideInfo>
                                <SideInfo>0725291113</SideInfo>
                            </SideGroup>
                            <ApprovalSection>
                                <Checkbox type="checkbox" onClick={approved}/>
                                <ApprovalText>Prin trimiterea mesajului, acceptați să folosim datele furnizate pentru a vă contacta referitor la solicitarea dumneavoastră.</ApprovalText>
                            </ApprovalSection>
                            <SendButton id="submitBtn" type="submit" onClick={sendEmail}>Trimite</SendButton>
                            <AlertSuccess id="alertSuccess" className={successState ? "form-visible" : "form-invisible"}>
                                <strong>Multumim!</strong> Mesajul a fost trimis cu succes!
                            </AlertSuccess>
                            <AlertFail id="alertFail" className={failState ? "form-visible" : "form-invisible"}>
                                <strong>Oops!</strong> Ceva nu a functionat. Va rugam sa mai incercati o data! 
                            </AlertFail>
                            <AlertWarning id="alertWarning" className={warningState ? "form-visible" : "form-invisible"}>
                                Trebuie sa va dati acordul pentru folosirea datelor personale pentru a putea trimite mesajul.
                            </AlertWarning>
                        </Side> 
                    </HorizontalContainer>
                </ContactForm>

            </Container>
        </div>
    )
}

export default ContactSection;