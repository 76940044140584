import styled from "styled-components";

export const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const InsideContainer = styled.div`
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
`;

export const Side = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin: 50px 2.5vw;
    width: 40vw;
    height: fit-content;

    @media screen and (max-width: 1000px) {
        width: 80vw;
    }

    &.imageSide {
        align-items: center;
    }
`;

export const AboutParagraph = styled.p`
    padding: 10px 50px;
    font-family: 'Castoro';
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;

    @media screen and (max-width: 1000px) {
        padding: 10px;
    }
`;

export const AboutSubtitle = styled.h1`
    text-transform: uppercase;
    margin: 25px auto;
`;

export const Bullets = styled.div`
    margin-bottom: 15px;
    margin-left: 25px;
`;

export const Bullet = styled.p`
    padding: 10px 50px;
    font-family: 'Castoro';
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
`;

export const SideGroup = styled.div`
    margin: 15px 0;
`;

export const SideInfo = styled.h2`
    text-align: left;
    justify-content: left;

    @media screen and (max-width: 1000px) {
        text-align: center;
    }

    & a {
        color: #0072ce;
    }

    &.lightFont {
        font-weight: 500;
    }
`;

export const ImageMap = styled.img`
    width: 30vw;

    @media screen and (max-width: 1000px) {
        width: 80vw;
    }
`;

export const InfoButton = styled.a`
    margin: 25px 10px;
    background: #0072ce;
    color: #fff;
    text-decoration: none;
    padding-top: 10px;
    text-align: center;
    height: 50px;
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #0072ce; 
    border-radius: 10px;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.2);

    &:hover {
        background: #fff;
        color: #0072ce;
    }
`;

export const LectorView = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 25px;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, auto);
    }
`;

export const TrainerView = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 25px;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, auto);
    }
`;

export const Member = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: top;
`;

export const MemberImage = styled.img`
    height: 300px;
    width: 200px;
    object-fit: cover;
    margin: 20px auto;
`;

export const MemberName = styled.h2`
    text-align: center;
`;
