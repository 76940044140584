import React, {useState} from 'react';
import AboutImage from '../components/Sections/About/AboutImage';
import { GalleryView, ImageButton, FullScreenContainer, LeftArrow, SelectedImage, CloseSign, RightArrow} from '../components/Sections/Gallery/Elements';
import { GalleryImages } from '../components/Sections/Gallery/GalleryImages';
import { Container, TitleLine, Title } from '../components/utils/Elements';
import './gallery.css';

function GallerySection() {

    const [activeImage, setActiveImage] = useState(-1);

    const activateImage = (value: number) => {
        if (value >= -1 && value < GalleryImages.length) {
            setActiveImage(value);
        }
        if (value === GalleryImages.length) {
            setActiveImage(-1);
        }
    }

    function FullScreenImage(props: {src: string, className: string}) {
        return (
            <FullScreenContainer className={props.className}>
                <LeftArrow onClick={() => activateImage(activeImage - 1)}/>
                <SelectedImage src={props.src}/>
                <CloseSign onClick={() => activateImage(-1)}/>
                <RightArrow onClick={() => activateImage(activeImage + 1)}/>
            </FullScreenContainer>
        )
    }

    return (
        <div id="gallery">
            <Container>
                <TitleLine>
                    <Title id="title">
                        Galerie
                    </Title>
                </TitleLine>
                <GalleryView>
                    {GalleryImages.map((items, idx) => {
                        return (
                            <ImageButton key={idx} onClick={() => activateImage(idx)}>
                                <AboutImage src={items.src} className="gallery"/>
                            </ImageButton>
                        );
                    })}
                </GalleryView>
            </Container>
            <FullScreenImage src={activeImage === -1 ? "" : GalleryImages[activeImage].src} 
                className={activeImage === -1 ? "inactive" : "active"}/>
        </div>
    )
}

export default GallerySection;