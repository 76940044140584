import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";

export const SectionLinks = [
  {
    title: 'Despre Noi',
    path: '#about',
    className: 'nav-link',
    target: ''
  },
  {
    title: 'Galerie',
    path: '#gallery',
    className: 'nav-link',
    target: ''
  },
  {
    title: 'Înscrieri',
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSfqJCTjxZdoLUuHFSfDcdyvRqOS9trVVZKLE_M2uap8yCFDKg/formResponse',
    className: 'nav-link',
    target: '_blank'
  },
  {
    title: 'Contact',
    path: '#contact',
    className: 'nav-link',
    target: ''
  },
];

export const SocialMediaLinks = [
  {
    icon: faFacebookF,
    href: 'https://www.facebook.com/progresivmedical/'
  },
  {
    icon: faInstagram,
    href: 'https://www.instagram.com/progresivmedical/?hl=en'
  }
];

export const Sponsors = [
  {
    image: "../../images/Sponsor1.png",
  },
  {
    image: "../../images/Sponsor2.png",
  },
  {
    image: "../../images/Sponsor3.png",
  },
  {
    image: "../../images/Sponsor4.png",
  },
  {
    image: "../../images/Sponsor5.png",
  },
  {
    image: "../../images/Sponsor6.png",
  },
  {
    image: "../../images/Sponsor7.png",
  },
];