import styled from 'styled-components'

export const Footer = styled.div`
    background: #eaeaea;
    color: rgb(0, 0, 0, 0.6);
    padding-bottom: 30px;
`;

export const CoordinatorArea = styled.div`
    background: white;
    margin: 0 auto;
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
`;

export const InsideCoordinator = styled.div`
    margin: 25px auto;
`;

export const Coordinator = styled.h2`
    margin: 10px;
    text-align: left;
    color: black;
`;

export const SponsorArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 10vw;
    background: white;
`;

export const SponsorsList = styled.div`
    width: 100%:
    padding: 50px 100px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
`;

export const SectionTitle = styled.h1`
    color: black;
    margin-top: 100px;
    text-transform: uppercase;
    text-align: center;
`;

export const SponsorTitle = styled.h3`
    color: black;
    text-align: center;
`;

export const SponsorImage = styled.img`
    // height: 100px;
    max-width: 80vw;
    margin: 25px 15px;
`;

export const Copyright = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px auto;
    padding-top: 25px;
    margin-top: 30px;

    .copyright-icon {
        margin: 0 5px;
    }
`;

export const TextLine = styled.div`
    margin: 5px auto;
    text-align: center;
`;

export const Cookies = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px;
`;

export const CookiesTitle = styled.h3`
    margin: 5px;
`;

export const CopyrightText = styled.h3`
    margin: 5px;
    align-text: center;
`;

