import React from 'react';
import styled from 'styled-components';

function AboutImage(props: {src: string, className?: string}) {
    return (
        <Container>
            <Horizontal>
                <StrongRect className={props.className}/>
                <WeakRect className={props.className}/>
            </Horizontal>
            <Horizontal>
                <WeakRect className={props.className}/>
                <StrongRect className={props.className}/>
            </Horizontal>
            <ImageContainer>
                <Image src={props.src} alt="" />
            </ImageContainer>
        </Container>
    )
}

const Container = styled.div`
    position: relatvie;
`;

const ImageContainer = styled.div`
    postition: relative;
`;

const Horizontal = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justiy-content: center;
`;

const StrongRect = styled.div`
    height: 250px;
    width: 200px;
    max-width: 45vw;
    background: #0072ce;

    &.gallery {
        background: #eaeaea;
    }
`;

const WeakRect = styled.div`
    height: 250px;
    width: 200px;
    max-width: 45vw;
    background: #fff;

    &.gallery {
        background: #eaeaea;
    }
`;

const Image = styled.img`
    position: absolute;
    top: 10px;
    left: calc((100% / 2) - 190px);
    height: 480px;
    width: 380px;
    max-width: calc(90vw - 20px);
    object-fit: cover;

    @media screen and (max-width: 425px) {
        left: calc((100% / 2) - 45vw + 10px);
    }
`;

export default AboutImage;